import {FaTv, FaGraduationCap, FaRegMoneyBillAlt} from 'react-icons/fa'; 

export const timeline_Elements=[
    {
        id: 6, 
        title: 'Incoming Solutions Engineering Intern',
        company: 'Deloitte LLC', 
        location: 'Seattle, Washington', 
        description: "I am an incoming intern at Deloitte. I'll be working in the Seattle Office as a Solutions Engineering Intern", 
        date: 'June 2022', 
        icon: <FaTv />,
        type: 'internship',
    }, 
    {
        id: 1, 
        title: 'Software Developer Intern',
        company: 'Ooma, Inc', 
        location: 'Chicago, IL', 
        description: "I am responsible for developing the status page for the Application Services team to display outages with devices throughout the world. Additionally, I am migrating their existing ticketing system to JIRA.", 
        date: 'August 2021-Present', 
        icon: <FaTv />,
        type: 'internship',
    },
    {
        id: 4, 
        title: 'Software Developer Intern',
        company: '7Qubes', 
        location: 'Chicago, IL', 
        description: "I was resposible with integrating APIs, such as ShipEngine API, in the flagship product 'PLUTUS'. On top of it, I developed UI for e-signature app to replace DocuSign in order for customers to save money.", 
        date: 'February 2021-July 2021', 
        icon: <FaTv />,
        type: 'internship',
    }, 
    {
        id: 5, 
        title: 'Web Developer',
        company: 'Illinois Institute of Technology', 
        location: 'Chicago, IL', 
        description: "Programmed check-in-kiosk app for a university department using MERN stack to enhance the UX of the students using idea shop maker space. Designed an app using Angular and Node improving the inventory management efficiency by 30%", 
        date: 'August 2020-February 2021', 
        icon: <FaTv />,
        type: 'internship',
    },
    {
        id: 2, 
        title: 'Leadership Academy Scholar',
        company: 'Illinois Institute of Technology', 
        location: 'Chicago, IL', 
        description: "Won the full tuition Leadership Academy scholarship awarded by the Leadership Academy at Illinois Tech. The academy offers leadership training to identify & develop the future lead.", 
        date: 'August 2020-Present', 
        icon: <FaRegMoneyBillAlt/>,
        type: 'school',
    }, 
    {
        id: 3, 
        title: "Bachelor of Science in Computer Science",
        company: 'Illinois Institute of Technology', 
        location: 'Chicago, IL', 
        description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptate, nihil.", 
        date: 'January 2019-Present', 
        icon: <FaGraduationCap />,
        type: 'school',
    },  
]; 